<template>
  <div class="pa-3">
    <v-data-table
      :headers="Headers"
      :items="admins"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>All Admins</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.AdminStatus="{ value }">
        {{ +value ? "Active" : "Inactive" }}
      </template>
      <template #item.actions="{ item }">
        <v-btn
          :to="{
            name: 'Admin',
            params: { editUser: { ...item }, editRoutes: item.RouteSelected },
          }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-icon
          icon
          small
          @click="delRecord(item)"
          title="Click to delete"
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "Admin",
  mixins: [snackMixin],
  data() {
    return {
      search: "",
      Headers: [
        { text: "FullName", value: "Fullname" },
        { text: "Username", value: "Username" },
        { text: "Position", value: "Position" },
        { text: "Admin Level", value: "AdminLevel" },
        { text: "Admin Status", value: "AdminStatus" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
      admins: [],
      routeAccessIds: [],
      routeAccess: [],
    };
  },

  created() {
    apiClient
      .get("/admin?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.admins = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    async delRecord(item) {
      const ok = confirm(`Are sure you want to delete ${item.Fullname}?`);
      if (!ok) {
        return;
      }

      await apiClient
        .get("/route_access?u=1&admin_id=" + item.id)
        .then((res) => {
          if (Array.isArray(res.data)) {
            if (res.data.length) {
              this.routeAccess = res.data;
              this.routeAccess.forEach((v) => {
                this.routeAccessIds.push(v.id);
              });
              this.handleDelete(item);
            } else {
              this.deleteAdminOnly(item);
            }
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => this.displayMsg(err, "error"));
    },

    async deleteAdminOnly(item) {
      try {
        const res1 = await apiClient.delete(`/admin?${item.id}`);
        if (res1.data.ok) {
          this.admins = this.admins.filter((rec) => rec.id !== item.id);
          this.displayMsg(`Successfully deleted ${item.Fullname} `);
        } else {
          this.displayMsg(`${item.Fullname} not successfully deleted`, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },

    async handleDelete(item) {
      try {
        const res1 = await apiClient.delete(`/admin?${item.id}`);
        const res2 = await apiClient.put(`/route_access`, this.routeAccessIds);
        if (res1.data.ok && res2.data.ok) {
          this.admins = this.admins.filter((rec) => rec.id !== item.id);
          this.displayMsg(`Successfully deleted ${item.Fullname} `);
        } else {
          this.displayMsg(`${item.Fullname} not successfully deleted`, "error");
        }
      } catch (err) {
        this.displayMsg(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
