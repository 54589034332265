var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-data-table',{staticClass:"elevation-1 tb px-2",attrs:{"headers":_vm.Headers,"items":_vm.admins,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('b',[_vm._v("All Admins")])]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.AdminStatus",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(+value ? "Active" : "Inactive")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{
          name: 'Admin',
          params: { editUser: Object.assign({}, item), editRoutes: item.RouteSelected },
        },"icon":"","x-small":"","title":"Click to edit","color":"black"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-icon',{attrs:{"icon":"","small":"","title":"Click to delete","color":"error"},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-snackbar',{attrs:{"bottom":"","color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Close ")],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('p',[_vm._v(_vm._s(_vm.snackMsg))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }